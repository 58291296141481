import { useStyles } from './CarouselSection.style';

import { Carousel } from '@/components/Carousel';
import { useResponsiveCarouselConfiguration } from '@/hooks/useResponsiveCarouselConfiguration';
import { type UseResponsiveCarouselConfigurationProps } from '@/hooks/useResponsiveCarouselConfiguration/useResponsiveCarouselConfiguration.types';

export interface CarouselSectionProps {
  breakpoints: UseResponsiveCarouselConfigurationProps;
  children?: React.ReactNode;
  headerText?: string;
  id?: string;
  loop?: boolean;
  seeAllLink?: string;
  slidesAmount?: number;
}

export const CarouselSection = ({
  breakpoints,
  children,
  headerText,
  id,
  loop,
  seeAllLink,
  slidesAmount
}: CarouselSectionProps): React.ReactElement => {
  const { classes } = useStyles();

  const { generatedBreakpoints, slidesToScroll } = useResponsiveCarouselConfiguration(breakpoints);

  return (
    <div id={id}>
      {(headerText && headerText.length > 0) && (
        <h2 className={classes.title}>{headerText}</h2>
      )}

      {(seeAllLink && seeAllLink.length > 0) && (
        <a href={seeAllLink} className={classes.seeAllButton}>
          See All
        </a>
      )}

      <Carousel
        controlsVariant="orange"
        loop={loop}
        slideSize={breakpoints.slideSize.default}
        slideGap={breakpoints.slideGap.default}
        breakpoints={generatedBreakpoints}
        slidesToScroll={slidesToScroll as number}
        slidesAmount={slidesAmount}
      >
        {children}
      </Carousel>
    </div>
  );
};
