import { createStyles } from '@mantine/core';

export const useStyles = createStyles(theme => ({
  featuredBrandsWrapper: {
    margin: '2rem auto'
  },

  slideWrapper: {
    display: 'block',
    padding: '10px',
    backgroundColor: 'white',
    border: '1px solid rgb(220, 220, 220)',
    borderRadius: '10px',
    textAlign: 'center',
    transition: 'box-shadow 150ms ease-in-out',

    '&:hover': {
      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px'
    }
  },

  image: {
    width: '100%',
    height: 'auto'
  }
}));
