import { useStyles } from './FeaturedBrands.style';

import { Slide } from '@/components/Carousel';
import { CarouselSection } from '@/components/CarouselSection';
import { Image } from '@/components/Image';
import { PartialWrapper } from '@/components/PartialWrapper';

interface Brand {
  alt?: string;
  href: string;
  image: string;
}

export interface FeaturedBrandsProps {
  brands: Brand[];
}

export const FeaturedBrands = ({ brands }: FeaturedBrandsProps): React.ReactElement => {
  const { classes } = useStyles();

  const breakpoints = {
    slideSize: {
      xs: '40%',
      default: '20%'
    },
    slideGap: {
      md: 8,
      default: 25
    },
    slidesToScroll: {
      xs: 2,
      default: 5
    }
  };

  return (
    <section id="featured-brands" className={classes.featuredBrandsWrapper}>
      <CarouselSection
        breakpoints={breakpoints}
        headerText="Featured Brands"
        seeAllLink="/brands"
        loop
      >
        {brands.map((brand, index) => (
          <Slide key={index}>
            <a className={classes.slideWrapper} href={brand.href}>
              <Image
                alt={brand.alt}
                className={classes.image}
                src={brand.image}
                width={160}
                height={75}
              />
            </a>
          </Slide>
        ))}
      </CarouselSection>
    </section>
  );
};

export const FeaturedBrandsWrapped = (props: FeaturedBrandsProps): React.ReactElement => (
  <PartialWrapper>
    <FeaturedBrands {...props} />
  </PartialWrapper>
);
