import { createStyles } from '@mantine/core';

export const useStyles = createStyles(theme => ({
  title: {
    color: '#444444',
    marginBottom: '1rem',
    marginTop: 0,
    paddingLeft: 0,
    fontWeight: 'normal',
    fontSize: '1.5rem',
    display: 'inline-block',
    fontFamily: '"Agrandir", Arial'
  },

  seeAllButton: {
    color: '#444444',
    marginLeft: '18px',
    padding: '3px 14px 4px',
    border: '1px solid #dcdcdc',
    borderRadius: '18px',
    backgroundColor: '#ffffff',
    textDecoration: 'none',

    '&:hover': {
      color: theme.colors.orange[5]
    }
  },

  carouselImage: {
    maxWidth: '100%'
  }
}));
