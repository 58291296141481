import { useEffect, useState } from 'react';

import { type CarouselBreakpoint } from '@mantine/carousel/lib/types';
import { useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

import {
  type Breakpoint,
  type UseResponsiveCarouselConfigurationProps,
  type ResponsiveCarouselConfiguration
} from './useResponsiveCarouselConfiguration.types';

export const useResponsiveCarouselConfiguration = (breakpoints: UseResponsiveCarouselConfigurationProps): ResponsiveCarouselConfiguration => {
  const theme = useMantineTheme();
  const xs = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);
  const sm = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
  const md = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
  const lg = useMediaQuery(`(max-width: ${theme.breakpoints.lg})`);
  const xl = useMediaQuery(`(max-width: ${theme.breakpoints.xl})`);

  const breakpointsList = ['xs', 'sm', 'md', 'lg', 'xl'];

  useEffect(() => {
    setSlidesToScroll(extractBreakpointValue(breakpoints.slidesToScroll));
  }, [xs, sm, md, lg, xl, breakpoints]);

  useEffect(() => {
    setGeneratedBreakpoints(generateBreakpointsObject());
  }, [breakpoints]);

  const extractBreakpointValue = (breakpoint: Breakpoint): number | string => {
    return (
      (xs && breakpoint.xs) ||
      (sm && breakpoint.sm) ||
      (md && breakpoint.md) ||
      (lg && breakpoint.lg) ||
      (xl && breakpoint.xl) ||
      breakpoint.default
    );
  };

  const generateBreakpointsObject = (): CarouselBreakpoint[] => {
    const result: CarouselBreakpoint[] = [];
    let latestSlideSize = breakpoints.slideSize.default;

    for (let i = 0; i < breakpointsList.length; i++) {
      const item: CarouselBreakpoint = { maxWidth: breakpointsList[i], slideSize: latestSlideSize };

      if (breakpoints.slideSize[breakpointsList[i]]) {
        item.slideSize = breakpoints.slideSize[breakpointsList[i]];
      }

      if (breakpoints.slideGap[breakpointsList[i]]) {
        item.slideGap = breakpoints.slideGap[breakpointsList[i]];
      }

      if (item.slideSize !== latestSlideSize || item.slideGap) {
        latestSlideSize = breakpoints.slideSize[breakpointsList[i]];
        result.push(item);
      }
    }

    return result;
  };

  const [generatedBreakpoints, setGeneratedBreakpoints] = useState(generateBreakpointsObject());
  const [slidesToScroll, setSlidesToScroll] = useState(extractBreakpointValue(breakpoints.slidesToScroll));

  return {
    generatedBreakpoints,
    slidesToScroll
  };
};
